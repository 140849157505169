import request from '@/utils/request'

export function getList(categoryId, query) {
	return request({
		url: '/appnews/list/'+categoryId,
		method: 'get',
		params: query
	})
}

export function getDetail(query) {
	return request({
		url: '/appnews/info',
		method: 'get',
		params: query
	})
}


