import request from '@/utils/request'

//类目列表
export function getCategories(query) {
	return request({
		url: '/appcategory/list',
		method: 'get',
		params: query
	})
}

//类目详情
export function getCategory(categoryId, query) {
	return request({
		url: '/appcategory/info/'+categoryId,
		method: 'get',
		params: query
	})
}


