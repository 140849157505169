<template>
  <div class="session session-business">
    <div class="content-inner" v-loading="loading">
      <div class="session-h1">{{category.name}}</div>
      <div class="session-h2">专业二手车交易运营服务商</div>

      <ul class="child-menu">
        <li style="width: 238px;" v-for="(category, index) in categories" :key="index">
          <router-link :to="'/contact/'+parentCategoryId+'/'+category.id" :class="activeCategory.id==category.id?'active':''">{{ category.name }}</router-link>
        </li>
        <li class="clear"></li>
      </ul>

      <div v-if="article" class="content-body" v-html="article.newsContent"></div>
      <el-empty v-if="!article" description="暂无内容"></el-empty>




    </div>
  </div>
</template>

<script>
import {getCategories, getCategory} from "@/api/category"
import {getDetail} from "@/api/articles";
export default {
  data() {
    return {
      loading: true,
      categories:[],
      category:{
        namd:'',
      },
      activeCategory:{
        id:0,
        name:''
      },
      parentCategoryId:0,
      categoryId:null,
      article: {
        newsContent:null
      }
    };
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    getCategories(){
      this.parentCategoryId = this.$route.params.id
      getCategories({categoryId:this.parentCategoryId}).then(response => {
        this.categories = response.data
        if(this.categories.length>0){
          this.getActiveCategory()
          //this.categoryId = this.categories[0].id
          //this.getDetail()
        }
        else{
          this.getDetail(this.$route.params.id)
        }
        this.loading = false

      })
      this.getCategory()


    },
    getActiveCategory(){
      if(this.$route.params.childId){
        this.activeCategory = this.categories.find(item=>item.id==this.$route.params.childId)
      }
      else{
        this.activeCategory = this.categories[0]
      }
      //子分类内容详情
      this.getDetail(this.activeCategory.id)
    },
    getDetail(categoryId){
      getDetail({categoryId:categoryId}).then(response => {
        this.article=response.data
      })
    },
    getCategory(){
      getCategory(this.$route.params.id).then(response => {
        this.category=response.data
      })
    },
  },
  watch:{
    $route(){
      this.getCategories()
    }
  }
};
</script>

