import axios from 'axios'
//import cookie from 'vue-cookie'
import env from '@/env.js'


//自定义实例默认值
const service = axios.create({
  baseURL: env.apiUrl,
  timeout: 5000 // request timeout
})



// request interceptor
service.interceptors.request.use(
  config => {
	//let accessToken = cookie.get('access_token');
	//if(accessToken){
	//	config.headers['Authorization'] = 'Bearer ' + accessToken
	//}
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    console.log(response.status);

      return res
  },
  error => {
    return Promise.reject(error);

  }
)

export default service
